import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalAppSettings } from '@features/global/global-app.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalAppApi {
  private http = inject(HttpClient);
  constructor() {}

  get() {
    return this.http.get<{ settings: GlobalAppSettings; isDirty: boolean }>('global-settings');
  }

  set(settings: GlobalAppSettings) {
    return this.http.put('global-settings', settings);
  }
}
